import axiosIns from "@/libs/axios";

export default {
  get: async () => {
    return await axiosIns.get(`proveedores`);
  },

  store: async (data) => {
    return await axiosIns.post("proveedores", data);
  },

  findOne: async (term) => {
    return await axiosIns.get(`proveedores/${term}`);
  },

  update: async (id, data) => {
    return await axiosIns.patch(`proveedores/${id}`, data);
  },

  destroy: async (id) => {
    return await axiosIns.delete(`proveedores/${id}`);
  },

  buscar: async ({ q }) => {
    return await axiosIns.get(`proveedores/buscar?q=${q}`);
  },
};
