import axiosIns from "@/libs/axios";

const store = async (data) => {
  return await axiosIns.post('cotizaciones', data)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`cotizaciones/pdf/${id}`)
}

const get = async (sede_id, fecha) => {
  return await axiosIns.get(`cotizaciones?sede_id=${sede_id}&fecha=${fecha}`)
}

export default {
  store,
  findOnePdf,
  get,
}
