import axiosIns from "@/libs/axios";

const get = async ({estado}) => {
  let query = estado != null ? `?estado=${estado}` : ''
  return await axiosIns.get(`tipos-documentos${query}`)
}

const store = async (data) => {
  return await axiosIns.post('tipos-documentos', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`tipos-documentos/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`tipos-documentos/${id}`)
}

export default {
  get,
  store,
  update,
  destroy
}
