<template>
  <div>
    <b-card title="Cotización">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12" lg="6" class="border-right">
              <b-row class="mb-0-5">
                <!-- <b-col cols="12" md="6">
                  <b-form-group
                    label="NIT/CI/CEX:"
                    label-for="numeroDocumentoComplemento"
                    label-cols="12"
                    label-cols-lg="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Numero de documento"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        @keyup.enter="buscarDocumento()"
                        placeholder="Buscar Nit/Ci/Cex (F8)"
                        id="numeroDocumentoComplemento"
                        v-model="nro_documento_complemento"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col cols="12" md="12">
                  <b-form-group
                    label="Cliente:"
                    label-for="numeroDocumentoComplemento"
                    label-cols="12"
                    label-cols-lg="2"
                  >
                    <v-select
                      id="cliente"
                      v-model="clienteItem"
                      label="razon_social_documento"
                      class="style-chooser select-size-sm"
                      placeholder="Buscar Cliente (Nombre, NIT, CI, CEX)"
                      :clearable="true"
                      :options="clienteItems"
                      @search="onSearchCliente"
                      @input="getClienteitem"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search"
                          >lo siento, no hay opciones de coincidencia</span
                        >
                        <span v-else>Digite, para buscar al cliente</span>
                      </template>
                      <template #list-header="{ search }">
                        <li
                          style="text-align: center"
                          v-if="search && clienteItems.length == 0"
                          @click="getClienteitem(null)"
                          class="add-new-client-header cursor-pointer"
                        >
                          <span class="">Registrar Nuevo Cliente </span>
                        </li>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de documento"
                        rules="required"
                      >
                        <b-form-group
                          label="Tipo Doc.:"
                          label-for="tipo_documento"
                          label-cols="12"
                          label-cols-lg="4"
                          :state="errors.length > 0 ? false : null"
                        >
                          <b-form-select
                            :disabled="disabledCliente"
                            id="tipo_documento"
                            size="sm"
                            v-model="formCliente.tipo_documento_id"
                            value-field="id"
                            text-field="descripcion"
                            :options="tipoDocumentoItems"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="8" md="4">
                      <b-form-group
                        label="Nro Doc:"
                        label-for="numero_documento"
                        label-cols="12"
                        label-cols-lg="3"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Numero de documento"
                          :rules="numeroDocumento"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="numero_documento"
                            v-model="formCliente.numero_documento"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4" md="2">
                      <b-form-group
                        label="Comp:"
                        label-for="complemento"
                        label-cols="12"
                        label-cols-lg="4"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Complemento"
                          rules="max:5"
                        >
                          <b-form-input
                            :disabled="
                              disabledCliente ||
                              formCliente.tipo_documento_id != 1
                            "
                            size="sm"
                            id="complemento"
                            :formatter="textFormatter"
                            lazy-formatter
                            placeholder=""
                            v-model="formCliente.complemento"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Razon social:"
                        label-for="razon_social"
                        label-cols="12"
                        label-cols-lg="4"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Razon social"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="razon_social"
                            :formatter="textFormatter"
                            lazy-formatter
                            v-model="formCliente.razon_social"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Correo:"
                        label-for="correo_electronico"
                        label-cols="12"
                        label-cols-lg="2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electronico"
                          rules="email|max:100"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="correo_electronico"
                            v-model="formCliente.correo_electronico"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" md="6" class="border-right">
              <b-row class="mb-0-5">
                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="12" lg="3">
                      <label for="fecha" style="padding: 0.51rem 0 0 0">
                        Fecha:
                      </label>
                    </b-col>
                    <b-col cols="12" lg="9">
                      <validation-provider
                        #default="{ errors }"
                        name="Fecha"
                        rules="required"
                        vid="fecha"
                      >
                        <b-form-datepicker
                          id="fecha"
                          v-model="transaccion.fecha"
                          label-no-date-selected="Seleccione una fecha"
                          label-help="Use las teclas del cursor para navegar por las fechas del calendario"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" md="6">
                  <b-row>
                    <b-col cols="12" lg="3">
                      <label for="validez" style="padding: 0.51rem 0 0 0">
                        Validez:
                      </label>
                    </b-col>
                    <b-col cols="12" lg="9">
                      <validation-provider
                        #default="{ errors }"
                        name="Validez"
                        rules="required|fecha_mayor_o_igual_a:@fecha"
                      >
                        <b-form-datepicker
                          id="validez"
                          v-model="transaccion.validez"
                          label-no-date-selected="Seleccione una fecha"
                          label-help="Use las teclas del cursor para navegar por las fechas del calendario"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          size="sm"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-0-5">
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Referencia:"
                    label-for="referencia"
                    label-cols="12"
                    label-cols-lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Referencia"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        id="referencia"
                        v-model="transaccion.referencia"
                        :formatter="textFormatter"
                        lazy-formatter
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    label="Forma Pago:"
                    label-for="formas_pago"
                    label-cols="12"
                    label-cols-lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Forma Pago"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        id="formas_pago"
                        v-model="transaccion.formas_pago"
                        :formatter="textFormatter"
                        lazy-formatter
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-0-5">
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Cotizador:"
                    label-for="nombre_cotizador"
                    label-cols="12"
                    label-cols-lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cotizador"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        id="nombre_cotizador"
                        v-model="transaccion.nombre_cotizador"
                        :formatter="textFormatter"
                        lazy-formatter
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    label="C.I. Cotizador:"
                    label-for="carnet_identidad_cotizador"
                    label-cols="12"
                    label-cols-lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="C.I. Cotizador"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        id="carnet_identidad_cotizador"
                        v-model="transaccion.carnet_identidad_cotizador"
                        :formatter="textFormatter"
                        lazy-formatter
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-0-5">
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="banco"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Banco:"
                      label-for="banco"
                      label-cols="12"
                      label-cols-lg="3"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select
                        id="banco"
                        size="sm"
                        v-model="transaccion.banco_id"
                        value-field="id"
                        text-field="descripcion"
                        :options="bancoItems"
                        :state="errors.length > 0 ? false : null"
                      >
                      </b-form-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-0-5">
                <b-col cols="12" lg="12">
                  <b-form-group
                    label="Periodo Garantía:"
                    label-for="periodo_garantia"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Periodo Garantía"
                      rules="required"
                    >
                      <b-form-input
                        size="sm"
                        id="periodo_garantia"
                        v-model="transaccion.periodo_garantia"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr class="hr" />

          <b-row class="mb-0-5">
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="6" lg="6">
                  <b-form-group
                    label="Código (Esc):"
                    label-for="codigo"
                    label-cols="12"
                    label-cols-lg="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MaximizeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="codigo"
                        v-model="codigo"
                        @keyup.enter="buscarCodigo()"
                        onClick="this.select();"
                        size="sm"
                        placeholder="Digite o Escanee el código"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-button
                    class="btnBuscarArticulo"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    block
                    @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                  >
                    BUSCAR ARTICULO (F9)
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="12">
              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-balham ag-grid-tableCotizacion"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady"
                :rowData="transaccion.detalleTransaccion"
                :suppressRowClickSelection="true"
                colResizeDefault="shift"
                :animateRows="false"
                :floatingFilter="false"
                :rowClassRules="rowClassRules"
                @rowClicked="onRowClicked($event)"
                :alwaysShowVerticalScroll="true"
                @first-data-rendered="onFirstDataRendered"
                :getRowStyle="getRowStyle"
                :pinnedBottomRowData="pinnedBottomRowData"
                @cell-value-changed="onCellValueChanged"
                @cell-key-down="onCellKeyDown"
              >
              </ag-grid-vue>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col cols="12" md="4">
              <b-form-group style="text-align: end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="dark"
                  class="mr-1"
                  @click.prevent="validar"
                >
                  REALIZAR COTIZACIÓN
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <ModalSelectArticulo
        ref="ModalSelectArticuloRef"
        esServicio="1"
        :ocultarCostoColumna="$hascan('venta-ver_costo')"
        @selectItemArticulo="selectItemArticulo"
      />
      <ModalPdfTransaccion
        :pdf="pdf"
        :pdfBase64="pdfBase64"
        :accionModalPdf="accionModalPdf"
        @cerrarModalPdf="cerrarModalPdf"
      />
    </b-card>
  </div>
</template>
<script>
import CotizacionServices from "../services/index";
import ProductoServices from "@/modules/administracion/producto/services/index";
import { ClienteService } from "@/modules/administracion/cliente_proveedor/services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import FacturacionServices from "@/modules/configuracion-sin/services/index";
import BancoServices from "@/modules/configuracion/banco/services/index";
import TipoDocumentoService from "@/modules/configuracion/tipo-documento/services/index";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";

export default {
  name: "NuevaCotizacionLayout",
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
    ModalPdfTransaccion,
    BFormDatepicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // modal articulo
      item: [],
      // table
      required,
      loading: false,
      metodoPagoItems: [],

      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "PRODUCTO",
          field: "descripcion",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "PRECIO",
          field: "precio",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: precioParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "DESCUENTO",
          field: "descuento",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: descuentoNumberFormatter,
          valueParser: descuentoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        // {
        //   headerName: "",
        //   field: "info",
        //   type: "numericColumn",
        //   filter: false,
        //   minWidth: 150,
        //   maxWidth: 150,
        // },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subtotal =
                param.data.cantidad * param.data.precio - param.data.descuento;
              return param.data.subtotal;
            }
            return param.node.data.subtotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>`;
            }
          },
        },
      ],
      // end grid
      submitted: false,
      // transaccion
      transaccion: {
        cliente_proveedor_id: null,
        usuario_id: null,
        tipo_transaccion_id: 4,
        subtotal: 0,
        descuento: 0,
        total: 0,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        sede_id: "",
        // cotizacion
        fecha: "",
        validez: "",
        referencia: "",
        formas_pago: "",
        nombre_cotizador: "",
        carnet_identidad_cotizador: "",
        periodo_garantia: "",
        banco_id: "",
      },
      tipoDocumentoItems: [],

      // variables de clientes
      nro_documento_complemento: "",
      cliente: "",
      disabledCliente: true,
      formCliente: {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        direccion: "",
        ciudad: "",
        zona: "",
        correo_electronico: "",
        celular: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      },

      disableGitcard: false,

      errores: [],
      codigo: "",
      clienteItems: [],
      clienteItem: null,
      debounce: null,
      bancoItems: [],
    };
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  watch: {
    "formCliente.tipo_documento_id": function () {
      if (this.formCliente.tipo_documento_id != 1) {
        if (this.formCliente.tipo_documento_id != "") {
          this.formCliente.complemento = "";
        }
      }
      this.errores.tipo_documento_id = [];
    },
    "formCliente.numero_documento": function () {
      this.errores.numero_documento = [];
    },
    "formCliente.complemento": function () {
      this.errores.complemento = [];
    },
    "formCliente.correo_electronico": function () {
      this.errores.correo_electronico = [];
    },
    "formCliente.razon_social": function () {
      this.errores.razon_social = [];
    },
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          descuento: "SUBTOTAL Bs",
          subtotal: this.calcularSubTotal(),
        },
        {
          descuento: "DESCUENTO Bs",
          edit: true,
          subtotal: this.transaccion.descuento,
          key: "descuento",
        },
        {
          descuento: "TOTAL Bs",
          subtotal: this.calcularTotal(),
          bold: true,
        },
      ];
    },
    numeroDocumento() {
      if (
        this.formCliente.tipo_documento_id == 1 ||
        this.formCliente.tipo_documento_id == 5
      ) {
        return "required|integer|max:20";
      } else {
        return "required|max:20";
      }
    },
  },
  methods: {
    onkey(event) {
      if (event.key == "F8") {
        document.getElementById("numeroDocumentoComplemento").focus();
        document.getElementById("numeroDocumentoComplemento").select();
      }
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
          try {
            const response = await ClienteService.buscar({
              q: search,
            });
            const data = response.data.map((g) => ({
              ...g,
              razon_social_documento:
                g.numero_documento + " - " + g.razon_social,
            }));
            this.clienteItems = data;
            loading(false);
          } catch (err) {
            console.log(err);
            loading(false);
          }
        }, 350);
      } else {
        loading(false);
        this.clienteItems = [];
      }
    },
    async getBanco() {
      try {
        const response = await BancoServices.get({});
        this.bancoItems = response.data;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    getClienteitem(item) {
      if (item == null) {
        setTimeout(() => {
          this.$nextTick(() => {
            document.getElementById("tipo_documento").focus();
          });
        }, 100);
        this.clienteItem = null;
        this.cliente = [];
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: "",
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      } else {
        this.disabledCliente = true;
        this.cliente = item;
        this.getCliente();
      }
    },
    // cliente
    async buscarDocumento() {
      try {
        const response = await ClienteService.findOne(
          this.nro_documento_complemento
        );
        this.disabledCliente = true;
        this.cliente = response.data;
        this.getCliente();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ADVERTENCIA!",
          variant: "warning",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.nro_documento_complemento;
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: this.nro_documento_complemento,
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      }
    },
    getCliente() {
      let complemento = this.cliente.complemento
        ? this.cliente.complemento
        : "";
      this.transaccion.cliente_proveedor_id = this.cliente.id;
      this.transaccion.razon_social = this.cliente.razon_social;
      this.transaccion.numero_documento = this.cliente.numero_documento;
      this.formCliente = {
        id: this.cliente.id,
        tipo_documento_id: this.cliente.tipo_documento_id,
        numero_documento: this.cliente.numero_documento,
        complemento: complemento,
        razon_social: this.cliente.razon_social,
        correo_electronico: this.cliente.correo_electronico,
        nro_documento_complemento: this.nro_documento_complemento,
        es_verificado: this.cliente.es_verificado ? 1 : 0,
      };
    },
    async getFormCliente() {
      this.formCliente.id = null;
      this.formCliente.nro_documento_complemento =
        this.formCliente.numero_documento + this.formCliente.complemento;
      // if (this.formCliente.tipo_documento_id == 5) {
      //   await this.verificarNit();
      // }
    },
    async registrarCliente() {
      await this.getFormCliente();
      try {
        const response = await ClienteService.store(this.formCliente);
        this.transaccion.cliente_proveedor_id =
          response.data.cliente_proveedor.id;
        this.transaccion.razon_social =
          response.data.cliente_proveedor.razon_social;
        this.transaccion.numero_documento =
          response.data.cliente_proveedor.numero_documento;

        this.disabledCliente = true;
        await this.registrar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.disabledCliente = false;
        this.submitted = false;
        this.errores = err.response.data.errors;
        if (this.errores.numero_documento !== undefined) {
          this.$bvToast.toast(this.errores.numero_documento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.complemento !== undefined) {
          this.$bvToast.toast(this.errores.complemento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.razon_social !== undefined) {
          this.$bvToast.toast(this.errores.razon_social[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.correo_electronico !== undefined) {
          this.$bvToast.toast(this.errores.correo_electronico[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
      }
    },

    // verificar nit
    // async verificarNit() {
    //   try {
    //     const response = await FacturacionServices.verficarNit(
    //       this.formCliente.numero_documento
    //     );
    //     if (response.data.transaccion) {
    //       this.formCliente.es_verificado = 1;
    //       this.$bvToast.toast("NIT Verificado", {
    //         title: "EXITO!",
    //         variant: "success",
    //         solid: false,
    //       });
    //     } else {
    //       this.$bvToast.toast("No se pudo verificar el NIT", {
    //         title: "ADVERTENCIA!",
    //         variant: "danger",
    //         solid: false,
    //       });
    //       this.formCliente.es_verificado = 0;
    //     }
    //   } catch (err) {
    //     this.$bvToast.toast("Error no hay comunicacion con siat", {
    //       title: "ADVERTENCIA!",
    //       variant: "danger",
    //       solid: false,
    //     });
    //   }
    // },

    // VALIDAR FORMULARIO
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.transaccion.detalleTransaccion.length > 0) {
            this.transaccion.sede_id = this.mixing.sede_id;
            this.submitted = true;
            if (this.disabledCliente) {
              this.registrarCotizacion();
            } else {
              this.registrarCliente();
            }
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },

    // registrar la cotizacion
    async registrarCotizacion() {
      if (
        this.formCliente.tipo_documento_id == 5 &&
        !this.formCliente.es_verificado
      ) {
        // await this.verificarNit();
        await this.registrar();
      } else {
        await this.registrar();
      }
    },
    async registrar() {
      try {
        const response = await CotizacionServices.store(this.transaccion);

        this.errores = [];
        this.limpiarForm();

        setTimeout(() => {
          this.submitted = false;
          this.$bvToast.toast("La cotización se realizo con correctamente", {
            title: "EXITO!",
            variant: "success",
            solid: false,
          });

          this.verPdf(response.data.id); // TODO: ver pdf
        }, 300);
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;

        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await CotizacionServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.transaccion.detalleTransaccion.splice(index, 1);
    },
    // calculos de totales
    calcularSubTotal() {
      let resultado = 0;
      for (
        let index = 0;
        index < this.transaccion.detalleTransaccion.length;
        index++
      ) {
        resultado =
          resultado + this.transaccion.detalleTransaccion[index].subtotal;
      }
      resultado = resultado.toFixed(2);
      // this.transaccion.subtotal = parseFloat(resultado);
      this.transaccion.subtotal = resultado;
      return resultado;
    },
    calcularTotal() {
      let resultado = 0;
      resultado =
        parseFloat(this.transaccion.subtotal) -
        parseFloat(this.transaccion.descuento);
      resultado = resultado.toFixed(2);
      // this.transaccion.total = parseFloat(resultado);
      this.transaccion.total = resultado;
      return resultado;
    },

    onCellValueChanged(event) {
      if (event.data.key == "descuento") {
        this.transaccion.descuento = event.data.subtotal;
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },

    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },

    // limpiar formulario
    limpiarForm() {
      this.transaccion = {
        cliente_proveedor_id: null,
        tipo_transaccion_id: 4,
        subtotal: 0,
        descuento: 0,
        total: 0,
        usuario_id: this.mixing.id,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        sede_id: this.mixing.sede_id,

        // cotizacion
        fecha: "",
        referencia: "",
        validez: "",
        formas_pago: "",
        periodo_garantia: "",
        nombre_cotizador: "",
        carnet_identidad_cotizador: "",
        banco_id: null,
      };
      this.clienteItem = null;
      this.cliente = "";
      this.nro_documento_complemento = "";
      this.disabledCliente = true;
      this.disableGitcard = true;
      this.codigo = "";

      this.formCliente = {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        correo_electronico: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      };
      this.getBanco();
      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
    },

    // llamadas ala api de sifacturo
    async getDocumentoIdentidad() {
      try {
        const response = await TipoDocumentoService.get({ estado: 1 });
        this.tipoDocumentoItems = response.data;
      } catch (err) {
        console.log(err);

        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        try {
          const response = await ProductoServices.findOneCodigoNoStock(
            this.codigo,
            this.mixing.sede_id,
            1
          );
          this.selectItemArticulo(response.data, false);
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    // encuentra articulo
    encuentraArticulo(id, estado) {
      var sw = false;
      for (let i = 0; i < this.transaccion.detalleTransaccion.length; i++) {
        if (this.transaccion.detalleTransaccion[i].id == id) {
          let cantidad =
            parseFloat(this.transaccion.detalleTransaccion[i].cantidad) + 1;
          let stock = parseFloat(this.transaccion.detalleTransaccion[i].stock);
          this.transaccion.detalleTransaccion[i].cantidad++;
          sw = true;
          if (estado) {
            setTimeout(() => {
              this.focusAgridCantidad(i);
            }, 120);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 120);
            this.gridApi.redrawRows();
          }
        }
      }
      return sw;
    },
    // Emit de select articulo
    selectItemArticulo(producto, estado) {
      if (producto) {
        setTimeout(() => {
          this.agregarItem(producto, estado);
        }, 100);
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA!",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
    },
    agregarItem(producto, estado) {
      if (!this.encuentraArticulo(producto.id, estado)) {
        this.transaccion.detalleTransaccion.push({
          id: producto.id,
          cantidad: 1,
          codigo: producto.codigo,
          descripcion: producto.descripcion,
          descuento: 0,
          costo: 0,
          precio: producto.precio_unitario,
          subtotal: producto.precio_unitario * 1,
          unidad: producto.unidad,
          stock: producto.stock,
        });
        if (estado) {
          setTimeout(() => {
            this.focusAgrid();
          }, 120);
        } else {
          setTimeout(() => {
            document.getElementById("codigo").focus();
            document.getElementById("codigo").select();
          }, 120);
          this.gridApi.redrawRows();
        }
      }
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },

    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.getDocumentoIdentidad();
    this.getBanco();
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
    this.transaccion.usuario_id = this.mixing.id;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function descuentoNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.cantidad;
  }
}
function precioParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.precio;
  }
}
function descuentoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.montoDescuento;
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 26.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.box {
  height: 9rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $primary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.btnBuscarArticulo {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .btnBuscarArticulo {
    margin-top: 0;
  }
}
</style>