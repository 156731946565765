import axiosIns from "@/libs/axios";

const get = async ({ page, limit, searchQuery }) => {
  return await axiosIns.get(`bancos`)
}

const store = async (data) => {
  return await axiosIns.post('bancos', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`bancos/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`bancos/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy
}
