import axiosIns from "@/libs/axios";

export default {
  get: async () => {
    return await axiosIns.get(`clientes`);
  },

  store: async (data) => {
    return await axiosIns.post("clientes", data);
  },

  findOne: async (term) => {
    return await axiosIns.get(`clientes/${term}`);
  },

  update: async (id, data) => {
    return await axiosIns.patch(`clientes/${id}`, data);
  },

  destroy: async (id) => {
    return await axiosIns.delete(`clientes/${id}`);
  },

  buscar: async ({ q }) => {
    return await axiosIns.get(`clientes/buscar?q=${q}`);
  },
};
